<template>
  <div class="container cleanfix">
    <div class="float-left">
      <a
        href="https://www.amazon.com/Amethyst-Dawnbringer-Genevieve-Rose-Vickers-ebook/dp/B08VGXB3DL"
        target="_blank"
        rel="noopener noreferrer"
        style="border:none;text-decoration:none"
        ><img class="image" id="book-cover" src="@/assets/cover.jpg"
      /></a>
    </div>
    <h1 class="emboss">Amethyst</h1>
    <p>
      Valkyrie Amethyst, despite her heroic name, has pretty modest goals. To go
      twenty-four hours without a panic-induced crying fit is one of them.
    </p>

    <p>
      However, when one has joined a team of amateur superheroes for shelter’s
      sake, there is, unfortunately, no room for crying fits. With her unbending
      boss pushing her into danger at every turn, the universe seems bent on
      confirming what Valk already knows: despite her inexplicable and
      destructive powers, she’s not, and will never be, safe enough to breathe
      again.
    </p>

    <p>
      Octavian Robinson, on the other hand, is a pretty happy guy. As a
      relatively senior member of Valk’s team, he’s quite comfy with his
      since-birth ability to make illusions. Aside from the occasional hero gig,
      his days are filled with sketchbooks, canvas shoes, big sweaters, and
      goofy grins.
    </p>

    <p>
      That, and internalized mental breakdowns.
    </p>

    <p>
      But Octavian has mastered pushing aside the cause of those over the years,
      and given that he’s the sole person that keeps his dysfunctional team from
      murdering one another, his smiles-for-miles attitude is needed now more
      than ever.
    </p>

    <p>
      His best friend’s demonic powers have just up and left him, and a single
      dapper redhead—the only person the team has ever seen with abilities like
      theirs—looks to be to blame. With a fair bit of yelling at one another but
      without a shred of a plan, Octavian and Valk’s team loads up their 2010
      minivan, and takes their crime-fighting skills (or lack thereof) on the
      road to hunt their first real supervillain down.
    </p>

    <p>
      And that supervillain, he calls Valk—Valk, of all people—the Dawnbringer.
    </p>

    <p>
      She doesn’t know what that means, but it sounds prophetic, and she’s going
      to hide from it in the trunk with the luggage, thank you very much.
    </p>
  </div>
</template>

<style scoped>
@media (min-width: 601px) {
  p {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .image {
    margin-top: 75px;
    margin-bottom: 0px;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media (max-width: 600px) {
  #book-cover {
    width: 80%;
    height: 80%;
  }
}
</style>
